.indicator {
    display: flex;
    list-style: none;
    margin: 0;
    justify-content: center;
    padding: 0;
}

.indicator-item a {
    border: 1px solid rgba(0, 0, 0, .5);
    height: 12px;
    width: 12px;
    border-radius: 6px;
    margin: 5px;
    display: block;
}

.indicator-item a:hover,
.indicator-item.active a {
    background: rgba(0, 0, 0, .5);
}

html, body, #root {
    height: 100%;
}