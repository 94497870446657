@value breakpoint-lg-down, breakpoint-md-up, breakpoint-xl-up from '../../app/assets/css/varaibles.css';

.wrapper {
    padding-top: 1.5em;
    margin-top: 1em;
    border-top: 1px solid #ccc;
    padding-right: 0.25em;
    padding-left: 1em;
    display: flex;
}

.wrapper:last-child {
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid #ccc;
}

.row {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.column {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
}

.label {
    overflow: hidden;
    text-overflow: ellipsis;
}

.input {
    width: 100%;
}

.trash {
    padding-left: 1em;
}

@media (breakpoint-md-up) {
    .column {
        flex-direction: row;
        align-items: center;
    }

    .label {
        width: 250px;
        margin-bottom: 0;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

@media (breakpoint-xl-up) {
    .wrapper:last-child,
    .wrapper {
        margin: 0;
        border: 0;
        padding-bottom: 0;
        padding-top: 0;
        padding-left: 0;
    }

    .row {
        flex-direction: row;
    }

    .column {
        flex-direction: column;
        padding-left: 1em;
    }

    .column:first-child {
        padding-left: 0;
    }

    .label {
        display: none;
        width: 100%;
        padding-left: 0.25em;
        height: 2em;
        white-space: nowrap;
    }

    .trash {
        display: flex;
        padding-bottom: 1em;
    }

    .wrapper:first-child .label {
        display: block;
    }

    .wrapper:first-child .trash {
        padding-top: 2em;
    }
}

@media (breakpoint-lg-down) {
    .column {
        width: 100% !important;
    }
}