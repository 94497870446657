@value breakpoint-md-up from '../assets/css/varaibles.css';

.header h1 {
    font-size: 22px;
}

@media (breakpoint-md-up) {
    .header h1 {
        font-size: 27px;
    }
}

.header img {
    width: 180px;
    position: relative;
}

.footer {
    font-size: 14px;
    background: #e7e7e7;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #9b9a9b;
}

.languageChange {
    position: relative;
    float: right;
}

.languageChange svg,
.languageChange .icon {
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 4px;
}

.languageChange select {
    opacity: inherit;
    margin: 0;
    border: 0;
    border-radius: 0;
    background: none;
    padding: 0;
    vertical-align: middle;
    line-height: inherit;
    letter-spacing: inherit;
    white-space: pre-wrap;
    font: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    padding-right: 30px;
    color: #4a494a;
}

.nav {
    display: flex;
}

.nav ul {
    list-style: none;
    display: flex;
    margin-top: 5px;
}

.nav li {
    margin-left: 20px;
}

.nav a {
    white-space: nowrap;
    font-size: 20px;
    color: rgb(116, 116, 116);
}

.nav a:hover {
    text-decoration: none;
    color: #4a494a;
}

.demo .content {
    background-image: url('../assets/images/demo_watermark.svg');
}