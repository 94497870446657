.step-nav {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
}

.step-nav-item a {
    display: block;
    background: #ececec;
    color: #777;
    font-weight: bold;
    padding: 10px 15px 10px 35px;
    position: relative;
    font-size: 14px;
    min-width: 80px;
    text-align: center;
    margin-bottom: 30px;
}

.step-nav {
    margin-bottom: 0;
}

.step-nav-item a:after {
    content: '';
    position: absolute;
    top: 2px;
    right: -15px;
    width: 36px;
    height: 37px;
    /*as you see the rotated square takes a larger height. which makes it tough to position it properly. So we are going to scale it down so that the diagonals become equal to the line-height of the link. We scale it to 70.7% because if square's:
    length = 1; diagonal = (1^2 + 1^2)^0.5 = 1.414 (pythagoras theorem)
    if diagonal required = 1; length = 1/1.414 = 0.707*/
    transform: scale(0.707) rotate(45deg);
    z-index: 1;
    box-shadow: 2px -2px 0 2px #aaa,
    3px -3px 0 2px rgba(255, 255, 255, 0.1);
    border-radius: 0 5px 0 50px;
    background: #ececec;
}

.step-nav-item a:hover {
    text-decoration: none;
}

.step-nav-item:last-child a:after {
    display: none;
}


.step-nav-item:first-child a {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.step-nav-item:last-child a {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.step-nav-item.active a,
.step-nav-item.active a:after,
.step-nav-item a:hover,
.step-nav-item a:hover:after {
    background: #ccc;
}

.step-nav-item.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.step-nav-item a {
    position: relative;
}

.StepNavItem-title {
    position: absolute;
    bottom: -25px;
    width: 300px;
    text-align: center;
    pointer-events: none;
    left: -115px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.step-nav-item:first-child .StepNavItem-title {
    left: 0;
    text-align: left;
}