body {
    margin: 0;
    font-family: "Roboto", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-pointer {
    cursor: pointer;
}

.sigCanvas {
    border: 1px solid #ddd;
}

.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.page-item.active .page-link {
    background-color: #2daad2;
    border-color: #2daad2;
}

a, .btn-link, a:hover, .btn-link:hover, a:active, .btn-link:active {
    color: #2daad2;
    text-decoration: none;
    background-color: transparent;
}

.btn-link:hover,
a:hover {
    text-decoration: underline;
}

.btn-secondary {
    background: #ececec;
    color: #777;
    border: none;
    font-weight: bold;
    text-align: center;
}

.btn-secondary:active,
.btn-secondary:hover {
    background: #ccc;
    color: #777;
}

.btn-secondary.disabled {
    opacity: 0.25;
    pointer-events: none;
}

.card {
    border-radius: 0;
    border: 0;
}

.card-header {
    background-color: rgba(0, 0, 0, .05);
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}

.text-eclipse {
    text-overflow: ellipsis;
    overflow: hidden;
}

.unit p,
.input-group-text p {
    padding: 0;
    margin: 0;
    display: inline;
}

.pe-none {
    pointer-events: none;
}

.popover-body {
    padding: 5px 10px;
}

.modal-title {
    font-size: 18px;
}

.was-validated .form-select:valid,
.was-validated .form-control:valid {
    padding: 0.375rem 0.75rem;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-image: none;
    box-shadow: none;
}